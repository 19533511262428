import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import s from './Landing.module.scss';
import sphynx from '../../Resources/sphynx.jpg';
import paris from '../../Resources/paris.jpg';
import gagarin from '../../Resources/gagarin.jpg';
import hands from '../../Resources/hands.jpg';
import hand from '../../Resources/hand.jpg';
import investIcon from '../../Resources/invest.svg';
import businessIcon from '../../Resources/business.svg';
import usersIcon from '../../Resources/users.svg';
import artistsIcon from '../../Resources/artists.svg';
import linesAnimated from '../../Resources/lines_animation.svg';
import Blocks from '../../Components/Blocks/Blocks';
import handPrint from '../../Resources/handprint.svg';
import leaveMarkArrow from '../../Resources/leave_mark_arrow.svg';
import { useTranslation } from 'react-i18next';
import LandingMobile from './Mobile/LandingMobile';
import Slider from './Slider/Slider';
import RectangleAnimation from './Animations/RectangleAnimation';
import TriangleAnimation from './Animations/TriangleAnimation';
import CircleAnimation from './Animations/CircleAnimation';
import { useGetCost } from '../../Hooks/Configuration/getCost';

const Landing = () => {
  const { t, i18n } = useTranslation();
  const [introItem, setIntroItem] = useState(1);
  const { areaCost } = useGetCost();

  useEffect(() => {
    const interval = setInterval(() => {
      setIntroItem(c => (c === 3 ? 1 : c + 1));
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className={s.containerMobile}>
        <LandingMobile />
      </div>
      <div className={cx(s.container, i18n.language.includes('vi') && s.vi)}>
        <div>
          <div className={s.grid}>
            <Blocks color="blue">
              <a href="https://thewall.global" className={s.leaveMark}>
                <img src={handPrint} alt="" />
                <div className={s.arrow}>
                  <p>{t('leave_a_mark')}</p>
                  <img src={leaveMarkArrow} alt="" />
                </div>
              </a>
            </Blocks>
            <Blocks color="black" size="large">
              <div className={s.intro}>
                <h1>The Wall Global</h1>
                <div className={s.items}>
                  {introItem === 1 && <p>{t('intro_1')}</p>}
                  {introItem === 2 && <p>{t('intro_2')}</p>}
                  {introItem === 3 && <p>{t('intro_3')}</p>}
                </div>
              </div>
            </Blocks>
            <Blocks color="white" transitionColor="black" direction="right" />
            <Blocks color="white" />
            <Blocks color="white" image={sphynx} direction="top" />
            <Blocks color="black" transitionColor="white" direction="left" />
            <Blocks color="blue" image={paris} direction="right" />
            <Blocks color="blue" />
            <Blocks color="white" />
          </div>
        </div>
        <div>
          <div className={s.grid}>
            <Blocks color="blue" />
            <Blocks color="black" size="wide">
              <h2>{t('how_it_works')}</h2>
            </Blocks>
            <Blocks color="blue" image={linesAnimated} />
            <Blocks color="black">
              <CircleAnimation />
            </Blocks>
            <Blocks color="white" size="large">
              <Slider />
            </Blocks>
            <Blocks color="black" transitionColor="blue" direction="bottom" />
            <Blocks color="black" image={gagarin} direction="bottom" />
            <Blocks color="black">
              <TriangleAnimation />
            </Blocks>
          </div>
        </div>
        <div>
          <div className={s.grid}>
            <Blocks color="black" size="wide">
              <h2>{t('for_whom')}</h2>
            </Blocks>
            <Blocks color="diagonal" />
            <Blocks color="blue" transitionColor="black" direction="right" />
            <Blocks color="blue" size="wide_v">
              <div className={s.for}>
                <img src={investIcon} />
                <h3>{t('investors')}</h3>
                <p>{t('investors_text1', {areaCost: areaCost || '-'})}</p>
                <p>{t('investors_text2')}</p>
                <p>{t('investors_text3')}</p>
                <p>{t('investors_text4')}</p>
              </div>
            </Blocks>
            <Blocks color="black" size="wide_v">
              <div className={s.for}>
                <img src={businessIcon} />
                <h3>{t('business')}</h3>
                <p>{t('business_text1')}</p>
                <p>{t('business_text2')}</p>
                <p>{t('business_text3')}</p>
              </div>
            </Blocks>
            <Blocks color="white" size="wide_v">
              <div className={cx(s.for, s.black)}>
                <img src={usersIcon} />
                <h3>{t('regular_users')}</h3>
                <p>{t('regular_users_text1')}</p>
                <p>{t('regular_users_text2')}</p>
                <p>{t('regular_users_text3')}</p>
              </div>
            </Blocks>
            <Blocks color="blue" size="wide_v">
              <div className={s.for}>
                <img src={artistsIcon} />
                <h3>{t('artists')}</h3>
                <p>{t('artists_text1')}</p>
                <p>{t('artists_text2')}</p>
                <p>{t('artists_text3')}</p>
              </div>
            </Blocks>
          </div>
        </div>
        <div>
          <div className={s.grid}>
            <Blocks color="diagonalReverse" />
            <Blocks color="black" size="wide">
              <h2>{t('how_to_use')}</h2>
            </Blocks>
            <Blocks color="black" transitionColor="white" direction="right" />
            <Blocks color="blue">
              <div className={s.howTo}>
                <p className={s.number}>1</p>
                <a
                  className={s.text}
                  href={`https://thewall.global/creating_metamask_wallet_${i18n.language.split('-')[0].toLowerCase() === 'ru' ? 'ru' : 'en'}.pdf`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                    {t('how_to_use_1')}
                </a>
              </div>
            </Blocks>
            <Blocks color="blue">
              <div className={s.howTo}>
                <p className={s.number}>2</p>
                <p className={s.text}>{t('how_to_use_2', {areaCost: areaCost || '-'})}</p>
              </div>
            </Blocks>
            <Blocks color="black">
              <RectangleAnimation />
            </Blocks>
            <Blocks color="black" image={hands} direction="left" />
            <Blocks color="black" />
            <Blocks color="white">
              <div className={cx(s.howTo, s.blue)}>
                <p className={s.number}>{t('or')}</p>
                <p className={s.text}>{t('how_to_use_or')}</p>
              </div>
            </Blocks>
            <Blocks color="blue">
              <div className={s.howTo}>
                <p className={s.number}>3</p>
                <p className={s.text}>{t('how_to_use_3')}</p>
              </div>
            </Blocks>
            <Blocks color="blue">
              <div className={s.howTo}>
                <p className={s.number}>4</p>
                <p className={s.text}>{t('how_to_use_4')}</p>
              </div>
            </Blocks>
            <Blocks color="black" transitionColor="white" direction="top" />
            <Blocks color="black" image={hand} direction="right" />
            <Blocks color="blue">
              <div className={s.howTo}>
                <p className={s.number}>5</p>
                <p className={s.text}>{t('how_to_use_5')}</p>
              </div>
            </Blocks>
            <Blocks color="blue" transitionColor="black" direction="left" />
            <Blocks color="white" />
            <Blocks color="diagonalBlue" />
            <Blocks color="blue" size="wide">
              <div className={s.actionBlock}>
                <img src={handPrint} />
              </div>
            </Blocks>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
