import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import s from './LandingMobile.module.scss';
import Blocks from '../../../Components/Blocks/Blocks';
import { useTranslation } from 'react-i18next';
import sphynx from '../../../Resources/sphynx.jpg';
import linesAnimated from '../../../Resources/lines_animation.svg';
import paris from '../../../Resources/paris.jpg';
import Slider from '../Slider/Slider';
import gagarin from '../../../Resources/gagarin.jpg';
import RectangleAnimation from '../Animations/RectangleAnimation';
import TriangleAnimation from '../Animations/TriangleAnimation';
import CircleAnimation from '../Animations/CircleAnimation';
import usersIcon from '../../../Resources/users_b.svg';
import investIcon from '../../../Resources/invest.svg';
import businessIcon from '../../../Resources/business.svg';
import artistsIcon from '../../../Resources/artists.svg';
import hands from '../../../Resources/hands.jpg';
import hand from '../../../Resources/hand.jpg';
import handPrint from '../../../Resources/handprint.svg';
import { useGetCost } from '../../../Hooks/Configuration/getCost';

const LandingMobile = () => {
  const [whomSelector, setWhomSelector] = useState(0);
  const [introItem, setIntroItem] = useState(1);
  const { t, i18n } = useTranslation();
  const { areaCost } = useGetCost();

  const handleSelector = value => () => setWhomSelector(value);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntroItem(c => (c === 3 ? 1 : c + 1));
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={cx(s.container, i18n.language.includes('vi') && s.vi)}>
      <div className={s.grid}>
        <Blocks color="white" transitionColor="blue" direction="right" />
        <Blocks color="black" size="large">
          <div className={s.introItems}>
            {introItem === 1 && <p>{t('intro_1')}</p>}
            {introItem === 2 && <p>{t('intro_2')}</p>}
            {introItem === 3 && <p>{t('intro_3')}</p>}
          </div>
        </Blocks>
        <Blocks color="black" size="wide_v">
          <div className={s.intro}>
            <h1>The Wall Global</h1>
          </div>
        </Blocks>
        <Blocks color="black" />
        <Blocks color="white" image={sphynx} direction="top" />
        <Blocks color="black" image={linesAnimated} />
        <Blocks color="blue" image={paris} direction="right" />
        <Blocks color="black" />
        <Blocks color="blue" size="wide">
          <a href="https://thewall.global" className={s.action}>
            <img src={handPrint} alt=""/>
            <p>{t('leave_a_mark')}</p>
          </a>
        </Blocks>
        <Blocks color="diagonal" />
        <Blocks color="white" size="wide_3">
          <h2 className={s.black}>{t('how_it_works')}</h2>
        </Blocks>
        <Blocks color="white" size="large_3">
          <Slider />
        </Blocks>
        <Blocks color="blue" />
        <Blocks color="black" transitionColor="white" direction="right" />
        <Blocks color="diagonalBlueReverse" />
        <Blocks color="black" size="wide_3">
          <h2>{t('for_whom')}</h2>
        </Blocks>
        <Blocks color="black" size="wide_3_free">
          <div className={cx(s.whom)}>
            <div className={s.items}>
              <p
                className={whomSelector === 0 && s.active}
                onClick={handleSelector(0)}
              >
                {t('investors')}
              </p>
              <p
                className={whomSelector === 1 && s.active}
                onClick={handleSelector(1)}
              >
                {t('business')}
              </p>
              <p
                className={whomSelector === 2 && s.active}
                onClick={handleSelector(2)}
              >
                {t('regular_users')}
              </p>
              <p
                className={whomSelector === 3 && s.active}
                onClick={handleSelector(3)}
              >
                {t('artists')}
              </p>
            </div>
            <div className={s.content}>
              <img
                src={usersIcon}
                style={{ display: whomSelector === 2 ? 'block' : 'none' }}
              />
              <img
                src={investIcon}
                style={{ display: whomSelector === 0 ? 'block' : 'none' }}
              />
              <img
                src={businessIcon}
                style={{ display: whomSelector === 1 ? 'block' : 'none' }}
              />
              <img
                src={artistsIcon}
                style={{ display: whomSelector === 3 ? 'block' : 'none' }}
              />
              {whomSelector === 0 && (
                <>
                  <h3>{t('investors')}</h3>
                  <div className={s.details}>
                    <p>{t('investors_text1', {areaCost: areaCost || '-'})}</p>
                    <p>{t('investors_text2')}</p>
                    <p>{t('investors_text3')}</p>
                    <p>{t('investors_text4')}</p>
                  </div>
                </>
              )}
              {whomSelector === 1 && (
                <>
                  <h3>{t('business')}</h3>
                  <div className={s.details}>
                    <p>{t('business_text1')}</p>
                    <p>{t('business_text2')}</p>
                    <p>{t('business_text3')}</p>
                  </div>
                </>
              )}
              {whomSelector === 2 && (
                <>
                  <h3>{t('regular_users')}</h3>
                  <div className={s.details}>
                    <p>{t('regular_users_text1')}</p>
                    <p>{t('regular_users_text2')}</p>
                    <p>{t('regular_users_text3')}</p>
                  </div>
                </>
              )}
              {whomSelector === 3 && (
                <>
                  <h3>{t('artists')}</h3>
                  <div className={s.details}>
                    <p>{t('artists_text1')}</p>
                    <p>{t('artists_text2')}</p>
                    <p>{t('artists_text3')}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </Blocks>
        <Blocks color="black" size="wide_3">
          <h2>{t('how_to_use')}</h2>
        </Blocks>
        <Blocks color="black">
          <p className={s.number}>1</p>
        </Blocks>
        <Blocks color="blue" size="large">
          <a
            className={s.howTo}
            href={`https://thewall.global/creating_metamask_wallet_${i18n.language.split('-')[0].toLowerCase() === 'ru' ? 'ru' : 'en'}.pdf`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('how_to_use_1')}
          </a>
        </Blocks>
        <Blocks color="black" image={gagarin} direction="bottom" />
        <Blocks color="blue" size="large">
          <p className={s.howTo}>{t('how_to_use_2', {areaCost: areaCost || '-'})}</p>
        </Blocks>
        <Blocks color="black">
          <p className={s.number}>2</p>
        </Blocks>
        <Blocks color="black" image={hand} direction="left" />
        <Blocks color="white" size="large">
          <div className={s.howToOr}>
            <p className={s.or}>{t('or')}</p>
            <p className={s.howTo}>{t('how_to_use_or')}</p>
          </div>
        </Blocks>
        <Blocks color="black" direction="right" transitionColor="white" />
        <Blocks color="black">
          <RectangleAnimation />
        </Blocks>
        <Blocks color="black">
          <p className={s.number}>3</p>
        </Blocks>
        <Blocks color="blue" size="large">
          <p className={s.howTo}>{t('how_to_use_3')}</p>
        </Blocks>
        <Blocks color="black">
          <TriangleAnimation />
        </Blocks>
        <Blocks color="blue" size="large">
          <p className={s.howTo}>{t('how_to_use_4')}</p>
        </Blocks>
        <Blocks color="black">
          <p className={s.number}>4</p>
        </Blocks>
        <Blocks color="black" image={hands} direction="right" />
        <Blocks color="black">
          <p className={s.number}>5</p>
        </Blocks>
        <Blocks color="blue" size="large">
          <p className={s.howTo}>{t('how_to_use_5')}</p>
        </Blocks>
        <Blocks color="black">
          <CircleAnimation />
        </Blocks>
        <Blocks color="black" size="wide_3">
          <a href="https://thewall.global" className={cx(s.action, s.footer)}>
            <img src={handPrint} alt="" />
            <p>{t('leave_a_mark')}</p>
          </a>
        </Blocks>
      </div>
    </div>
  );
};

export default LandingMobile;
