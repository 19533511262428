import React, { useEffect, useState } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import cx from 'classnames';
import s from './Blocks.module.scss';

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const Blocks = ({
  color,
  transitionColor,
  size,
  image,
  children,
  direction
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, []);

  const handleObserver = event => {
    if (event.isIntersecting) {
      setTimeout(() => {
        setVisible(true);
      }, randomIntFromInterval(500, 1500));
    }
  };

  return (
    <Observer
      rootMargin="50% 0% 0%"
      onChange={handleObserver}
      disabled={visible}
    >
      <div className={cx(s.small, s[color], s[size], visible && s.visible)}>
        {image && (
          <div
            style={{ backgroundImage: `url(${image})` }}
            className={cx(s.image)}
          />
        )}
        {image && direction && visible && (
          <div className={cx(s.animation, s[color], s[direction])} />
        )}
        {transitionColor && visible && (
          <div className={cx(s.animation, s[transitionColor], s[direction])} />
        )}
        {children}
      </div>
    </Observer>
  );
};

export default Blocks;
