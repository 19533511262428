import React from 'react';
import './index.scss';
import './Locale/i18n';
import App from './Containers/App/App';
import { hydrate, render } from "react-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const client = new ApolloClient({
    uri: 'https://api.studio.thegraph.com/query/21669/the-wall/version/latest',
    cache: new InMemoryCache(),
  });

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<ApolloProvider client={client}><App /></ApolloProvider>, rootElement);
} else {
    render(<ApolloProvider client={client}><App /></ApolloProvider>, rootElement);
}
