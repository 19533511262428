import React from 'react';
import s from './Animations.module.scss';
import Triangle from '../../../Resources/Triangle';
import cx from 'classnames';

const TriangleAnimation = () => {
  return (
    <div className={s.triangleAnimation}>
      {[...Array(36)].map((i, k) => (
        <Triangle className={cx(s.item, k % 15 === 0 && s.blue)} />
      ))}
    </div>
  );
};

export default TriangleAnimation;
