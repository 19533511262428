import React from 'react';
import s from './Animations.module.scss';
import cx from 'classnames';

const RectangleAnimation = () => {
  return (
    <div className={s.rectangleAnimation}>
      {[...Array(25)].map((i, k) => (
        <div className={cx(s.item, k === 4 && s.blue)} />
      ))}
    </div>
  );
};

export default RectangleAnimation;
