import React, { useEffect } from 'react';
import Landing from '../Landing/Landing';
import Footer from '../../Components/Footer/Footer';
import Web3 from 'web3';
import Cookies from 'js-cookie';

function App() {
    useEffect(() => {
        const search = new URLSearchParams(window.location.search);
        const refAddress = search.get('invite');
        if (refAddress && Web3.utils.isAddress(refAddress)) {
            Cookies.set('referrer', refAddress, { expires: 365, path: '/' });
            localStorage.setItem('referrer', refAddress);
        }
    }, []);

  return (
    <>
      <Landing />
      <Footer />
    </>
  );
}

export default App;
