import React, { useRef, useState } from 'react';
import cx from 'classnames';
import slide0 from '../../../Resources/slide0.svg';
import slide1 from '../../../Resources/slide1.svg';
import slide2 from '../../../Resources/slide2.svg';
import slide3 from '../../../Resources/slide3.svg';
import slide4 from '../../../Resources/slide4.svg';
import slide5 from '../../../Resources/slide5.svg';
import { useTranslation } from 'react-i18next';
import s from './Slider.module.scss';
import nextArrow from '../../../Resources/slider_next.svg';
import backArrow from '../../../Resources/slider_back.svg';
import backArrowDisable from '../../../Resources/slider_back_disable.svg';
import nextArrowDisable from '../../../Resources/slider_next_disable.svg';
import ReactSlider from 'react-slick';
import { useGetCost } from '../../../Hooks/Configuration/getCost';

const Slider = () => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const sliderRef = useRef(null);
  const { areaCost } = useGetCost();

  const handleNext = () => {
    if (sliderRef.current && index < 5) {
      sliderRef.current.slickNext();
    }
  };

  const handleBack = () => {
    if (sliderRef.current && index > 0) {
      sliderRef.current.slickPrev();
    }
  };

  const handleSlide = (i, v) => {
    setIndex(v);
  };

  return (
    <>
      <img
        src={index < 5 ? nextArrow : nextArrowDisable}
        className={cx(s.nextArrow, index === 5 && s.disable)}
        onClick={handleNext}
      />
      <img
        src={index > 0 ? backArrow : backArrowDisable}
        className={cx(s.backArrow, index === 0 && s.disable)}
        onClick={handleBack}
      />
      <ReactSlider
        ref={sliderRef}
        arrows={false}
        dots
        infinite={false}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        className={s.slider}
        beforeChange={handleSlide}
      >
        <div className={s.slide}>
          <img src={slide0} />
          <p>{t('slide_0')}</p>
        </div>
        <div className={s.slide}>
          <img src={slide1} />
          <p>{t('slide_1', {areaCost: areaCost || '-'})}</p>
        </div>
        <div className={s.slide}>
          <img src={slide2} />
          <p>{t('slide_2')}</p>
        </div>
        <div className={s.slide}>
          <img src={slide3} />
          <p>{t('slide_3')}</p>
        </div>
        <div className={s.slide}>
          <img src={slide4} />
          <p>{t('slide_4')}</p>
        </div>
        <div className={s.slide}>
          <img src={slide5} />
          <p>{t('slide_5')}</p>
        </div>
      </ReactSlider>
    </>
  );
};

export default Slider;
