import { useQuery, gql } from '@apollo/client';
import Web3 from 'web3';

const AREA_COST = gql`
  query configurationAreaCost {
    configuration(id: "0") {
      areaCost
    }
  }
`;

export const useGetCost = () => {
  const { loading, data } = useQuery(AREA_COST, {pollInterval: 60000,});

  const areaCost = data && data.configuration && data.configuration.areaCost ? Web3.utils.fromWei(data.configuration.areaCost, "ether") : null;

  return { loading, areaCost };
};
