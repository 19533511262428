import React from 'react';
import s from './Animations.module.scss';
import cx from 'classnames';

const CircleAnimation = () => {
  return (
    <div className={s.circleAnimation}>
      {[...Array(25)].map((i, k) => (
        <div className={cx(s.item, k % 3 && s.delay)} />
      ))}
    </div>
  );
};

export default CircleAnimation;
