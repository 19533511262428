import React from 'react';

const Triangle = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="13"
    fill="none"
    viewBox="0 0 10 13"
    {...props}
  >
    <path fill="#fff" d="M9.95 6.475l-9.787 5.65V.825l9.787 5.65z"></path>
  </svg>
);

export default Triangle;
